<nav class="advanced-nav" *ngIf="showNavbar">
    <div class="nav-container">
      <div class="logo-text-container">
        <img src="./logo.png" alt="Logo" class="logo-img">
        <span class="logo-text">DreamMenders</span>
      </div>
      <div class="menu-toggle" id="mobile-menu">☰</div> <!-- Mobile Menu Icon -->
      <div class="menu" id="nav-menu">
        <a routerLink="/">Home</a>
        <div class="services-menu">
          <a  class="dropdown-toggle">Services <i class="fas fa-angle-down"></i></a> <!-- Dropdown Toggle with Icon -->
          <ul class="dropdown">
            <li><a routerLink="/website-development"><i class="fas fa-globe"></i> Website Development</a></li>
            <li><a routerLink="/seo-services"><i class="fas fa-search"></i> SEO Services</a></li>
            <li><a routerLink="/social-media-marketing"><i class="fas fa-bullhorn"></i> Social Media Marketing</a></li>
            <li><a routerLink="/ai-chatbots"><i class="fas fa-robot"></i> AI Chatbots</a></li>
          </ul>
        </div>
        <!-- <a href="https://blog.dreammenders.com" target="_blank" >Blog</a> -->
        <a routerLink="/contact-us">Contact Us</a>
      </div>
    </div>
  </nav>
  
  
  <router-outlet></router-outlet>
  
  <!-- Footer -->
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-column">
        <h3>About DreamMenders</h3>
        <p>DreamMenders is a full-service marketing agency offering website development, SEO, and social media marketing solutions to help businesses grow and succeed online.</p>
      </div>
      <div class="footer-column">
        <h3>Quick Links</h3>
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/website-development">Website Development</a></li>
          <li><a routerLink="/seo-services">SEO Services</a></li>
          <li><a routerLink="/social-media-marketing">Social Media Marketing</a></li>
          <li><a routerLink="/ai-chatbots"> AI Chatbots</a></li>
          <!-- <li><a href="https://blog.dreammenders.com" target="_blank" >Blog</a></li> -->
          <li><a routerLink="/contact-us">Contact Us</a></li>
          <li><a routerLink="/privacypolicy" target="_blank" >Privacy Policy</a></li>
        </ul>
      </div>
      <div class="footer-column contact-info">
        <h3>Contact Information</h3>
        <p>Email: <a href="mailto:info@dreammenders.com" class="footer-link">info&#64;dreammenders.com</a></p>
        <p>Phone: <a href="tel:+919494505790" class="footer-link">+91-949-450-5790</a></p>
        <p>Follow us: <a href="https://www.facebook.com/people/DreamMenders/61566403181926/" class="footer-link">Facebook</a> | <a href="https://www.instagram.com/dreammenders/" class="footer-link">Instagram</a></p>
      </div>
    </div>
    <div class="bottom-footer">
      <p>&copy; 2024 DreamMenders. All rights reserved.</p>
    </div>
  </footer>
  