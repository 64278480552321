<section class="contact">
    <h1>Contact Us</h1>
    <p>We would love to hear from you! Please fill out the form below to get in touch.</p>

    <!-- Contact Form -->
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        
        <!-- Name Field -->
        <div>
            <input type="text" formControlName="name" placeholder="Your Name" required>
            <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" class="error-message">
                Name is required
            </div>
        </div>

        <!-- Email Field -->
        <div>
            <input type="email" formControlName="email" placeholder="Your Email" required>
            <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched" class="error-message">
                Please enter a valid email
            </div>
        </div>

        <!-- Subject Field -->
        <div>
            <input type="text" formControlName="subject" placeholder="Subject" required>
            <div *ngIf="contactForm.get('subject')?.invalid && contactForm.get('subject')?.touched" class="error-message">
                Subject is required
            </div>
        </div>

        <!-- Message Field -->
        <div>
            <textarea formControlName="message" rows="5" placeholder="Your Message" required></textarea>
            <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched" class="error-message">
                Message is required
            </div>
        </div>

        <!-- Submit Button -->
        <button type="submit" [disabled]="contactForm.invalid">Send Message</button>
    </form>

    <!-- Success and Error Messages -->
    <p *ngIf="successMessage" class="success-message">{{ successMessage }}</p>
    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
</section>
