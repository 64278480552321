import { Component } from '@angular/core';

@Component({
  selector: 'app-social-media-marketing',
  templateUrl: './social-media-marketing.component.html',
  styleUrl: './social-media-marketing.component.css'
})
export class SocialMediaMarketingComponent {

}
