import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-chatbots',
  templateUrl: './ai-chatbots.component.html',
  styleUrl: './ai-chatbots.component.css'
})
export class AiChatbotsComponent {

}
