<section class="hero">
    <div class="hero-content">
        <h1>Social Media Marketing Services</h1>
        <p>At DreamMenders, we specialize in creating impactful social media marketing campaigns that drive engagement and brand loyalty. We help businesses grow their presence on platforms like Facebook, Instagram, LinkedIn, and more.</p>
        <div class="button-container">
            <a href="contact.html" class="cta-button">Get in Touch</a>
        </div>
    </div>
</section>


<section class="service-details">
    <h2>Why Choose Our Social Media Marketing Services?</h2>
    <div class="service-highlights">
        <div class="highlight">
            <img src="./content-creation.webp" alt="Content Creation" class="highlight-image">
            <h3>Content Creation</h3>
            <p>We create engaging and shareable content tailored to your audience.</p>
        </div>
        <div class="highlight">
            <img src="./targeted-advertising.webp" alt="Targeted Advertising" class="highlight-image">
            <h3>Targeted Advertising</h3>
            <p>Our targeted ad campaigns reach your ideal customers effectively.</p>
        </div>
        <div class="highlight">
            <img src="./analytics-tracking.webp" alt="Analytics Tracking" class="highlight-image">
            <h3>Analytics Tracking</h3>
            <p>We monitor and analyze performance to optimize campaigns for better results.</p>
        </div>
        <div class="highlight">
            <img src="./community-management.webp" alt="Community Management" class="highlight-image">
            <h3>Community Management</h3>
            <p>We engage with your audience and manage your social media interactions.</p>
        </div>
    </div>

    <h2>Our Social Media Strategy</h2>
    <p>Our social media strategy includes understanding your brand, defining your audience, creating a content calendar, and continuous monitoring to ensure success.</p>
    <div class="button-container">
        <a href="contact.html" class="cta-button">Get in Touch</a>
    </div>
</section>

<section class="seo-content">
    <h2>Comprehensive SEO-Driven Social Media Marketing</h2>
    <p>At DreamMenders, we understand that social media marketing is not just about posting content. It's about crafting a strategy that aligns with your overall marketing goals and utilizes SEO techniques to ensure your content reaches the right audience.</p>
    <h3>Key Elements of Our SEO Strategy:</h3>
    <div class="seo-elements">
        <div class="seo-box">Keyword Research: Identifying trending keywords and phrases that resonate with your target audience.</div>
        <div class="seo-box">On-Page Optimization: Ensuring all social media posts are optimized for search engines, including meta tags, descriptions, and more.</div>
        <div class="seo-box">Link Building: Establishing authority through backlinks from reputable sources and engaging content that encourages shares.</div>
        <div class="seo-box">Analytics and Reporting: Providing detailed reports on social media performance and SEO impact, helping you make informed decisions.</div>
    </div>
    <h3>Benefits of SEO in Social Media Marketing</h3>
    <div class="seo-benefits">
        <div class="benefit-box">Enhance visibility across multiple platforms.</div>
        <div class="benefit-box">Drive organic traffic to your website.</div>
        <div class="benefit-box">Improve engagement rates and brand awareness.</div>
        <div class="benefit-box">Attract high-quality leads that convert into customers.</div>
    </div>
</section>