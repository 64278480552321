import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Import from @angular/forms
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../Services/toast.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.css'
})
export class ContactUsComponent {
  contactForm: FormGroup;
  successMessage: string = '';
  errorMessage: string = '';

  constructor(private fb: FormBuilder, private http: HttpClient,private toastService: ToastService) {
    // Initialize the form with form controls and validators
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  // Mark all form fields as touched to trigger validation on first submit
  markAllFieldsAsTouched() {
    Object.keys(this.contactForm.controls).forEach(field => {
      const control = this.contactForm.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true }); // Mark each field as touched
      }
    });
  }

  onSubmit() {
    // Mark all fields as touched to display validation errors on first click
    this.markAllFieldsAsTouched();

    // Check if the form is valid
    if (this.contactForm.valid) {
      const formData = this.contactForm.value;

      // Send form data to the backend API
      this.http.post('https://emailnotification.dreammenders.com/send-email', formData).subscribe(
        (response: any) => {
          this.successMessage = 'Your message has been sent successfully!';
          this.toastService.showSuccess('Your message has been sent successfully!');
          this.errorMessage = '';
          this.contactForm.reset();
        },
        (error: any) => {
          this.errorMessage = 'Failed to send message. Please try again later.';
          this.successMessage = '';
        }
      );
    } else {
      this.errorMessage = 'Please fill in all fields correctly.';
    }
  }
}
