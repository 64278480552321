import { Component, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';  // Import PLATFORM_ID
import { isPlatformBrowser } from '@angular/common'; 
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {

  showNavbar = true;

  // List of routes where you want to hide the navbar
  hideNavbarRoutes: string[] = ['/privacypolicy', '/privacypolicy.html'];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private router: Router) { 

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      // Check if the current route is in the list of routes where the navbar should be hidden
      this.showNavbar = !this.hideNavbarRoutes.includes(event.urlAfterRedirects);
    });

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const mobileMenuIcon = document.getElementById('mobile-menu');
      const navMenu = document.getElementById('nav-menu');
      
      // Ensure mobileMenuIcon and navMenu are not null
      if (mobileMenuIcon && navMenu) {
        mobileMenuIcon.addEventListener('click', () => {
          navMenu.classList.toggle('active');
        });
   
      

        const menuLinks = navMenu.querySelectorAll('a');
        
        // Ensure each link is clicked to close the menu in mobile view
        menuLinks.forEach(link => {
          link.addEventListener('click', () => {
            if (window.innerWidth <= 768) {
              navMenu.classList.remove('active'); // Hide the menu after clicking a link
            }
          });
        });
      }
    }
  }

}
