import { Component } from '@angular/core';

@Component({
  selector: 'app-seo-services',
  templateUrl: './seo-services.component.html',
  styleUrl: './seo-services.component.css'
})
export class SeoServicesComponent {

}
