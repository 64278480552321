  
    <section class="hero">
        <div class="hero-content">
            <h1>SEO Services</h1>
            <p>Boost your website's visibility and drive organic traffic with our expert SEO services.</p>
            <a href="/contact-us" class="cta-button">Get in Touch</a>
        </div>
    </section>

    <section class="service-details">
        <h2>Why Choose Our SEO Services?</h2>
        <p class="intro-text">Our SEO services are designed to help your business grow and succeed online by improving your search engine rankings and driving organic traffic to your website. Whether you’re a small startup or a large enterprise, we have the expertise and tools to boost your online presence.</p>

        <div class="service-highlights">
            <div class="highlight">
                <img src="./keyword-research.webp" alt="Keyword Research" class="highlight-image">
                <h3>Keyword Research</h3>
                <p>We identify the best keywords to target for your business niche, helping to increase your visibility and attract the right audience.</p>
            </div>
            <div class="highlight">
                <img src="./on-page-optimization.webp" alt="On-Page Optimization" class="highlight-image">
                <h3>On-Page Optimization</h3>
                <p>Our team optimizes your website structure, content, and meta tags for better search engine performance and user engagement.</p>
            </div>
            <div class="highlight">
                <img src="./link-building.webp" alt="Link Building" class="highlight-image">
                <h3>Link Building</h3>
                <p>We create a strong backlink profile to enhance your website's authority and boost your search engine rankings.</p>
            </div>
            <div class="highlight">
                <img src="./analytics-reporting.webp" alt="Analytics and Reporting" class="highlight-image">
                <h3>Analytics and Reporting</h3>
                <p>We provide regular performance reports with insights to track your SEO progress and improve your strategy.</p>
            </div>
        </div>

        <h2>Our SEO Process</h2>
        <div class="seo-process">
            <div class="process-step">
                <h3>Website Audit</h3>
                <p>We start by conducting a full audit of your website to identify technical SEO issues that may be preventing your site from ranking well.</p>
            </div>
            <div class="process-step">
                <h3>Keyword Research</h3>
                <p>We perform in-depth keyword research to find the most valuable keywords for your industry, ensuring we target keywords that will bring qualified traffic to your site.</p>
            </div>
            <div class="process-step">
                <h3>On-Page SEO</h3>
                <p>We optimize your website’s on-page SEO, including updating meta titles, descriptions, header tags, image alt text, and content structure.</p>
            </div>
            <div class="process-step">
                <h3>Technical SEO Optimization</h3>
                <p>Technical SEO is critical to the success of your strategy. We ensure your site has fast loading times, mobile-friendliness, SSL certificates, and structured data.</p>
            </div>
            <div class="process-step">
                <h3>Content Strategy</h3>
                <p>We create high-quality, relevant content that aligns with your business goals, attracts traffic, and engages your audience.</p>
            </div>
            <div class="process-step">
                <h3>Link Building</h3>
                <p>We build a strong backlink profile by acquiring quality links from reputable and authoritative websites, helping boost your site's credibility.</p>
            </div>
            <div class="process-step">
                <h3>Analytics and Reporting</h3>
                <p>We provide detailed reports to track your site's progress, offering insights into traffic, keyword rankings, bounce rates, and conversions.</p>
            </div>
        </div>

        <p class="contact-line">Contact us today to learn more about our SEO services and start growing your business!</p>
        <div class="cta-container">
            <a href="contact-us" class="cta-button">Get in Touch</a>
        </div>
    </section>

    <section class="additional-content">
        <h2>The Importance of SEO for Business Growth</h2>
        <div class="content-card">
            <p>In today's competitive digital world, SEO is essential for any business that wants to improve its online visibility and attract more customers. Search Engine Optimization (SEO) is a strategy that focuses on improving your website’s organic search engine rankings. This process increases your visibility and drives more traffic to your site, allowing you to capture more leads and convert visitors into customers.</p>
        </div>
    
        <h3>Why SEO Matters</h3>
        <div class="content-card">
            <p>SEO is important because it helps your website rank higher on search engine results pages (SERPs). When your website ranks higher, it gets more visibility, more visitors, and more opportunities to convert those visitors into paying customers. SEO also provides a cost-effective, long-term solution compared to paid advertising.</p>
        </div>
    
        <h3>Local SEO for Targeted Traffic</h3>
        <div class="content-card">
            <p>For businesses that rely on local customers, local SEO is crucial. Local SEO focuses on optimizing your site so it appears in local search results. This includes optimizing your Google My Business profile, creating locally relevant content, and building local citations, ensuring that your business stands out in your community.</p>
        </div>
    
        <h2>Measuring SEO Success</h2>
        <div class="content-card">
            <p>Success in SEO isn’t just about ranking high on Google; it’s about achieving measurable business results. We track every aspect of your SEO campaign and provide you with detailed reports to measure your ROI. From traffic increases to higher conversion rates, we ensure that your investment in SEO delivers tangible results.</p>
        </div>
    </section>