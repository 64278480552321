import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {

  constructor(private meta: Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Home | DreamMenders - SEO, Website Development, Digital Marketing');
    this.meta.addTags([
      { name: 'description', content: 'Welcome to DreamMenders, your partner for SEO, website development, and digital marketing services.' },
      { name: 'keywords', content: 'SEO, website development, digital marketing, DreamMenders' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'Home | DreamMenders' },
      { property: 'og:description', content: 'Expert SEO and web development services for growing businesses.' },
      { property: 'og:image', content: 'https://dreammenders.com/path-to-image.jpg' },
      { property: 'og:url', content: 'https://dreammenders.com/' }
    ]);
  }

}
