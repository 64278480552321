import { Component } from '@angular/core';

@Component({
  selector: 'app-website-development',
  templateUrl: './website-development.component.html',
  styleUrl: './website-development.component.css'
})
export class WebsiteDevelopmentComponent {

}
