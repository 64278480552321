
<section class="hero">
    <div class="hero-content">
        <h1>Website Development Services</h1>
        <p>Custom-built, SEO-optimized websites to elevate your online presence.</p>
        <div class="cta-container">
            <a href="contact-us" class="cta-button">Get in Touch</a>
        </div>
    </div>
</section>

<section class="service-details">
    <h2>Why Choose Our Services?</h2>
    <div class="service-highlights">
        <div class="highlight fade-in">
            <img src="./seo-optimization.webp" alt="SEO Optimization" class="highlight-image" loading="lazy">
            <h3>SEO-Optimized</h3>
            <p>We build websites that are optimized for search engines to improve visibility and drive organic traffic.</p>
        </div>
        <div class="highlight fade-in">
            <img src="./responsive-design.webp" alt="Responsive Design" class="highlight-image" loading="lazy">
            <h3>Responsive Design</h3>
            <p>Our websites are fully responsive, ensuring they look great on all devices.</p>
        </div>
        <div class="highlight fade-in">
            <img src="./fast-loading.webp" alt="Fast Loading" class="highlight-image" loading="lazy">
            <h3>Fast Loading Times</h3>
            <p>We prioritize website speed to enhance user experience and reduce bounce rates.</p>
        </div>
        <div class="highlight fade-in">
            <img src="./secure-hosting.webp" alt="Secure Hosting" class="highlight-image" loading="lazy">
            <h3>Secure Hosting</h3>
            <p>We offer secure hosting solutions to keep your website safe and reliable.</p>
        </div>
    </div>

    <h2>Our Development Process</h2>
    <p>Our development process involves understanding your business goals, designing a unique website, and implementing the latest technologies to ensure the best performance.</p>

    <h2>Comprehensive Web Development Solutions</h2>
    <div class="solution-container">
        <div class="solution-box fade-in">
            <h3>E-commerce Development</h3>
            <p>Create online stores with secure payment gateways and a seamless shopping experience.</p>
        </div>
        <div class="solution-box fade-in">
            <h3>Content Management Systems (CMS)</h3>
            <p>Develop easy-to-manage websites using platforms like WordPress, Joomla, and Drupal.</p>
        </div>
        <div class="solution-box fade-in">
            <h3>Custom Web Applications</h3>
            <p>Build tailored web applications that meet specific business requirements.</p>
        </div>
        <div class="solution-box fade-in">
            <h3>Landing Page Development</h3>
            <p>Design high-converting landing pages to capture leads and boost conversions.</p>
        </div>
    </div>
</section>

<section class="industry-expertise">
    <h2>Our Expertise in Various Industries</h2>
    <div class="industry-container">
        <div class="industry-box fade-in">
            <h3>Healthcare</h3>
            <p>Develop websites for hospitals, clinics, and medical professionals with patient-friendly interfaces.</p>
        </div>
        <div class="industry-box fade-in">
            <h3>Real Estate</h3>
            <p>Create property listing websites with advanced search functionality and lead capture forms.</p>
        </div>
        <div class="industry-box fade-in">
            <h3>Education</h3>
            <p>Build online learning platforms and school websites with interactive features.</p>
        </div>
        <div class="industry-box fade-in">
            <h3>Hospitality</h3>
            <p>Design restaurant and hotel websites with booking systems and menus.</p>
        </div>
    </div>
</section>
<section class="get-in-touch-section">
    <h2>Let's Build Something Amazing Together</h2>
    <p>Whether you're looking to create a brand-new website or improve your current online presence, DreamMenders is here to help. Our expert team is dedicated to building beautiful, functional, and high-performance websites that deliver results.</p>
    <div class="cta-container">
        <a href="contact-us" class="cta-button">Get in Touch</a>
    </div>
</section>